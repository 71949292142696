export const CRON_NAMES_DISPLAY = {
	'test job': `Test Job`,
	'tn events': `TN Events`,
	'pricing': `Pricing`,
	'open table': `Open Table`,
	'clean logs': `Clean Logs`,
	'trade desk': `Trade Desk`,
	'frequency pricing': `Frequency Pricing`,
	'restaurants update': `Restaurants Update`,
	'statistics': 'Statistics',
	'alternative name check': 'Alternative Name Check',
};
